import _ from 'lodash';
import { collectionItemSubmit } from '../../../Store/Actions/actionsExtra';
import { store } from '../../../Store/Store';
import { TCollectionItemSubmit } from '../../../Types/typesFetch';
import { TSiteActionProps } from '../../../Types/typesGlobal';

export const ActionSetItem = (arg: TSiteActionProps['argument']) => {
  if (
    !_.isObject(arg) ||
    !_.has(arg, 'id') ||
    !_.has(arg, 'collectionItem') ||
    !_.has(arg, 'type')
  ) {
    console.log(`
    Check argument {
      id: string;
      collectionItem: Partial<TUser | TUnit>;
      type: string;
      onSucceed?: TSiteActionProps | TSiteActionProps[];
      onRejected?: TSiteActionProps | TSiteActionProps[];
    }`);
    return;
  }

  store.dispatch(collectionItemSubmit(arg as unknown as TCollectionItemSubmit));
};
