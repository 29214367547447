import { useDispatch } from 'react-redux';
import { TCustomCollection } from '../types';
import { useEffect } from 'react';
import { getCollectionRequest } from '../../../../../Store/Actions/actionsExtra';
import { TCollection } from '../../../../../Store/Slices/types';

export const useCollection = (
  inView: boolean,
  props: TCustomCollection,
  storedCollection?: TCollection
): TCollection => {
  const {
    id,
    name,
    variables,
    force,
    onSucceed,
    onRejected,
    pagination,
    unitRelations,
  } = props;

  const dispatch = useDispatch();
  const status = storedCollection?.fetchStatus || 'initial';
  const page = storedCollection?.pagination?.page;
  const pagesCount = storedCollection?.pagination?.pageCount;

  // console.table([
  //   { id, status, page, pageSize: pagination?.pageSize, pagesCount, inView },
  // ]);

  useEffect(() => {
    if (inView) {
      if (
        status === 'initial' ||
        (status === 'success' && page && pagesCount && pagesCount > page)
      ) {
        const newPagination = storedCollection?.pagination
          ? {
              ...storedCollection.pagination,
              page: storedCollection.pagination.page + 1,
            }
          : undefined;
        dispatch(
          getCollectionRequest({
            id,
            name,
            variables,
            unitRelations,
            force,
            onSucceed,
            onRejected,
            pagination: newPagination || pagination,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, status]);

  if (!storedCollection) return { id, name, fetchStatus: status };
  return storedCollection;
};
