import { schema } from 'normalizr';

/**
 * Нормализация юнитов
 *
 * @param depth - глубина нормализации
 * @returns схема
 */
const schemaNormalizeUnits = (): schema.Entity => {
  return new schema.Entity('units', {
    // ownerUnit: new schema.Entity('units'),
    ownUnits: [new schema.Entity('units')],
    memberUnits: {
      others: [new schema.Entity('units')],
      self: [new schema.Entity('units')],
      both: [new schema.Entity('units')],
    },
    membershipUnits: {
      others: [new schema.Entity('units')],
      self: [new schema.Entity('units')],
      both: [new schema.Entity('units')],
    },
    // ownerPerson: new schema.Entity('users'),
    memberPersons: {
      others: [new schema.Entity('users')],
      self: [new schema.Entity('users')],
      both: [new schema.Entity('users')],
    },
    // type: new schema.Entity('unitTypes'),
  });
};

export default schemaNormalizeUnits;
