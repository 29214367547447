import { TSiteActionProps } from '../../Types/typesGlobal';
import { ActionWebhook } from './ActionWebhook/ActionWebhook';
import { ActionNavigateTo } from './ActionNavigateTo/ActionNavigateTo';
import { ActionLogOut } from './ActionLogOut/ActionLogOut';
import { ActionGetDOCX } from './ActionGetDOCX/ActionGetDOCX';
import { ActionGetQRCode } from './ActionGetQRCode/ActionGetQRCode';
import { ActionThrow } from './ActionThrow/ActionThrow';
import { ActionGetCollection } from './ActionGetCollection/ActionGetCollection';
import { ActionSetCustomState } from './ActionSetCustomState/ActionSetCustomState';
import { ActionSwitchBooleanCustomState } from './ActionSwitchBooleanCustomState/ActionSwitchBooleanCustomState';
import { ActionShowToast } from './ActionShowToast/ActionShowToast';
import { ActionDeleteToast } from './ActionDeleteToast/ActionDeleteToast';
import { ActionSetQuery } from './ActionSetQuery/ActionSetQuery';
import { ActionRedirectTo } from './ActionRedirectTo/ActionRedirectTo';
import { ActionSetMe } from './ActionSetMe/ActionSetMe';
import { ActionSetToken } from './ActionSetToken/ActionSetToken';
import { ActionNavigateBack } from './ActionNavigateBack/ActionNavigateBack';
import { ActionSetItem } from './ActionSetItem/ActionSetItem';

/**
 * Класс Action.
 */
export class Action {
  exec(props: TSiteActionProps) {
    switch (props.name) {
      case 'navigateTo':
        ActionNavigateTo(props.argument);
        break;

      case 'navigateBack':
        ActionNavigateBack();
        break;

      case 'redirectTo':
        ActionRedirectTo(props.argument);
        break;

      case 'logOut':
        ActionLogOut();
        break;

      case 'webHook':
        ActionWebhook(props.argument);
        break;

      case 'getDocx':
        ActionGetDOCX(props.argument);
        break;

      case 'throw':
        ActionThrow(props.argument);
        break;

      case 'getQRCode':
        ActionGetQRCode(props.argument);
        break;

      case 'getCollection':
        ActionGetCollection(props.argument);
        break;

      case 'setItem':
        ActionSetItem(props.argument);
        break;

      case 'setUIState':
        ActionSetCustomState(props.argument);
        break;

      case 'switchBooleanUIState':
        ActionSwitchBooleanCustomState(props.argument);
        break;

      case 'showToast':
        ActionShowToast(props.argument);
        break;

      case 'deleteToast':
        ActionDeleteToast(props.argument);
        break;

      case 'setQuery':
        ActionSetQuery(props.argument);
        break;

      case 'setMe':
        ActionSetMe(props.argument);
        break;

      case 'setToken':
        ActionSetToken(props.argument);
        break;

      default:
        console.log(`Action "${props.name}" not found!`);
    }
  }
}
