import { call, put } from 'redux-saga/effects';
import {
  getCollectionRequest,
  getCollectionRequestPending,
  getCollectionRequestRejected,
  getCollectionRequestSucceed,
} from '../Actions/actionsExtra';
import { store } from '../Store';
import { TResponseObject } from '../../Types/typesFetch';
import { fetchApi } from './Helpers/fetchApi';
import {
  API_ENDPOINTS,
  API_URL_PREFIX,
  fetchErrorActions,
} from '../../Settings/api';
import _ from 'lodash';
import { execMultiplyActions } from '../../Library/Action/Helpers/execMultiplyActions';
import { Mutator } from '../../Library/Mutator/Mutator';
import { schemaNormalize } from '../Normalizers/schemaNormalize';
import { TSliceContent } from '../Slices/Content/sliceContent';
import { TAbstractStoreObject } from '../../Types/typesGlobal';
import { normalize } from 'normalizr';
import { fetchOtherPages } from './Helpers/fetchOtherPages';
import { constructMutatorContexts } from '../../Library/Mutator/Helpers/constructMutatorContexts';

export function* workerGetCollection({
  payload,
}: ReturnType<typeof getCollectionRequest>) {
  const {
    id,
    name,
    force,
    variables,
    pagination,
    onSucceed,
    onRejected,
    unitRelations,
  } = payload;
  const rootState = store.getState();
  const fetchStatus =
    rootState.sliceContent.collections[name]?.fetchStatus || 'initial';

  if (fetchStatus !== 'initial' && !pagination?.page && !force) return;

  yield put(getCollectionRequestPending({ id, name }));

  const url = API_URL_PREFIX + API_ENDPOINTS.collections + '/' + name;
  const parameters = {
    locale: store.getState().sliceUI.locale,
    variables,
    unitRelations,
    pagination,
  };

  const firstResponse: TResponseObject = yield call(() => {
    return fetchApi({
      url,
      method: 'GET',
      parameters,
    });
  });

  const responseOthers: TResponseObject = yield call(() => {
    if (!pagination?.page) {
      return fetchOtherPages(
        url,
        parameters,
        !pagination && fetchStatus === 'initial' ? firstResponse : undefined
      );
    }
  });

  const responseObject = responseOthers || firstResponse;

  const context = constructMutatorContexts();
  const mutator = new Mutator({
    ...context,
    current: { request: { response: responseObject } },
  });

  if (
    responseObject.error ||
    !responseObject.data ||
    !responseObject.meta?.type
  ) {
    const error: TResponseObject['error'] = responseObject.error
      ? responseObject.error
      : !responseObject.data
      ? { status: 400, name: 'ApplicationError', message: 'Data not found' }
      : { status: 400, name: 'ValidationError', message: 'Unknown data type' };

    yield put(getCollectionRequestRejected({ id, error }));

    const defaultErrorActions =
      fetchErrorActions.find(
        (item) =>
          item.status === responseObject.error?.status &&
          item.message === responseObject.error?.message
      )?.actions || [];

    const payloadErrorActions = onRejected
      ? _.isArray(onRejected)
        ? onRejected
        : [onRejected]
      : [];

    execMultiplyActions(
      [...defaultErrorActions, ...payloadErrorActions],
      mutator
    );
  } else {
    const responseData = responseObject.data;
    const dataArray = _.isArray(responseData) ? responseData : [responseData];
    const dataType = responseObject.meta.type as keyof TSliceContent['items'];

    const normSchema = schemaNormalize(dataType);
    const allCollection = normSchema
      ? normalize(
          {
            [dataType]: dataArray,
          },
          normSchema()
        ).entities
      : ({
          dataType: _.reduce(
            dataArray as unknown as TAbstractStoreObject[],
            (r, v) => ({ ...r, [v.id]: v }),
            {}
          ),
        } as Partial<TSliceContent['items']>);

    const itemIDs = dataArray.map((item) => item.id);

    yield put(
      getCollectionRequestSucceed({
        id,
        itemIDs,
        itemsType: dataType,
        pagination: responseObject.meta.pagination,
        items: allCollection,
      })
    );

    yield call(() => {
      const payloadSucceedActions = onSucceed
        ? _.isArray(onSucceed)
          ? onSucceed
          : [onSucceed]
        : [];

      execMultiplyActions(payloadSucceedActions, mutator);
    });
  }
}
