import _ from 'lodash';
import { TSiteActionProps } from '../../../Types/typesGlobal';
import { store } from '../../../Store/Store';
import { getCollectionRequest } from '../../../Store/Actions/actionsExtra';
import { TCollectionRequest } from '../../../Types/typesFetch';

export const ActionGetCollection = (arg: TSiteActionProps['argument']) => {
  if (!_.isObject(arg) || !_.has(arg, 'name')) {
    console.log(`
    Check argument {
      name: string;
      force?: boolean;
      onSucceed?: TSiteActionProps | TSiteActionProps[];
      onRejected?: TSiteActionProps | TSiteActionProps[];
    }`);
    return;
  }

  store.dispatch(
    getCollectionRequest({
      ...(arg as unknown as TCollectionRequest),
      force: true,
    })
  );
};
