import { TSliceContent } from './sliceContent';
import { TCollection } from '../types';
import { schemaNormalize } from '../../Normalizers/schemaNormalize';
import { denormalize } from 'normalizr';

export const getContentCollection = (
  id: string,
  normCollections: TSliceContent['collections'],
  contentItems: TSliceContent['items']
): TCollection | undefined => {
  const normCollection = normCollections[id];
  if (!normCollection) return;
  const type = normCollection.itemsType as keyof TSliceContent['items'];
  if (!type) return;
  const normItems = contentItems[type];
  if (!normItems) return;
  const normSchema = schemaNormalize(type, true);
  if (!normSchema) return;
  const items = normCollection.items?.length
    ? Object.values(normItems)
        .filter((item) => normCollection.items?.includes(item.id))
        .map((item) => denormalize(item, normSchema(), contentItems))
    : [];
  return {
    ...normCollection,
    items,
  };
};
